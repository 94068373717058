import { useRef } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import getConfig from 'next/config';

import fastDeepEqual from 'fast-deep-equal';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import { getAkamaiZipCode } from '@atc/akamai-fns';

import { getAndSaveConsumerMetadata } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { getActiveExperiments } from 'reaxl-optimizely';

import {
    authDuck,
    birfDuck,
    currentPageNameDuck,
    inventoryDuck,
    queryDuck,
    userDuck,
} from '@/ducks';

import { srpFiltersDuck, srpNoSearchResultsDuck, srpPaginationDuck, srpSortDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

import AlphaModule from '@/modules/AlphaModule';
import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

import { dataLayerTransform } from '@/transformers';

import { pageIdsByPageName } from './analyticsUtils';

export default function useDataLayer(data = {}) {

    // Ref authConsumerMetadataRef so we don't have to hit aws everytime
    const authConsumerMetadataRef = useRef({});
    const isLoggedInRef = useRef(false);

    // TODO we should store this somewhere instead of pulling it out of context and passing it in to the hook
    const { totalBoostCount } = data;

    const [cookies = {}] = useCookies(['abc', 'pxa_id', 'pixall_abc']);
    const query = useSelector(queryDuck.selectors.getDuckState);
    const dataIsland = useSelector(birfDuck.selectors.getPageData);
    const zip = useSelector(userDuck.selectors.getZip);
    const inventory = useSelector(inventoryDuck.selectors.selectHydratedInventory);
    const personalization = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerInsights, _isEqual);
    const pageName = useSelector(currentPageNameDuck.selectors.getDuckState);
    const consumerAdTargets = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargets, _isEqual);
    const consumerAdTargetsWithoutCAMPBias = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerAdTargetsWithoutCAMPBias, _isEqual);
    const consumerMarketingSegments = useSelector(PersonalizationEngineModule.duck.selectors.selectConsumerMarketingSegments, _isEqual);

    const userZipCode = getAkamaiZipCode();

    // This is to ensure we get the latest isLoggedIn state of user when consumerMetadata is called
    isLoggedInRef.current = useSelector(authDuck.selectors.isLoggedIn);

    const consumerMetadata = async () => {

        const isUserLoggedIn = isLoggedInRef.current;
        const hasAuthData = !_isEmpty(authConsumerMetadataRef.current);

        // If user is logged in, get from authentication
        // Otherwise get them from api
        const consumerMetadataResponse = isUserLoggedIn && hasAuthData ? { ...authConsumerMetadataRef.current } : await getAndSaveConsumerMetadata({
            isLoggedIn: isUserLoggedIn,
        });

        // Reset authConsumerMetadata ref
        // For edge case when user login, log out and then relogin with another account
        authConsumerMetadataRef.current = isUserLoggedIn ? { ...authConsumerMetadataRef.current } : {};

        return { ...consumerMetadataResponse };
    };

    const { brand } = useBrand();
    const device = useDevice();
    const { publicRuntimeConfig } = getConfig();

    // TODO: BONNET NEXT - suspense - this code is suspcious and at one point seemed to be breaking suspense if not broken into separate selectors
    const { vdpActiveResults, filters, sort, currentPage, showNoResults } = useSelector((state) => {
        if (pageName === 'vdp') {
            return {
                vdpActiveResults: vdpResultsDuck.selectors.getActiveResults(state),
            };
        }

        return {
            filters: srpFiltersDuck.selectors.getRequestValues(state),
            sort: srpSortDuck.selectors.getDuckState(state),
            currentPage: srpPaginationDuck.selectors.getDuckState(state)?.currentPage,
            showNoResults: srpNoSearchResultsDuck.selectors.getDuckState(state)?.showNoResults,
        };
    }, fastDeepEqual);

    let vehicle;
    let expiredVdp = false;

    // should we get active vehicle on SRP?
    // if not, is this why telMetric tag is missing data? is theis why GA360 events missing CDs 100-160?
    if (Array.isArray(vdpActiveResults) && vdpActiveResults.length === 1 && pageName === 'vdp') {
        vehicle = inventory[vdpActiveResults[0]];
    }

    if (pageName === 'vdp' && !vehicle) {
        expiredVdp = true;
    }

    const overrideFilterValues = {};

    if (filters) {
        const { homeServices = [], productTypes = [] } = filters;

        if (homeServices.length > 0 || productTypes.length > 0) {
            overrideFilterValues.productTypes = [...productTypes, ...homeServices];
        }
    }

    const isBuyOnline = query?.experience?.includes('buy-online');

    const {
        siteSection = '',
        pageType = '',
        detailPageName = '',
    } = dataLayerTransform({ pageName, showNoResults, expiredVdp, isBuyOnline });

    const {
        pg = '',
        brandPg = '',
    } = pageIdsByPageName[pageName]?.({
        brand,
        ...vehicle,
        ...filters,
        showNoResults,
        query,
    }) || {};

    const matchAlphaZone = useSelector(AlphaModule.duck.selectors.getAlphaZoneData);

    const activeExperiments = getActiveExperiments();
    return {
        // NOTE: Catalog should be 'atc' instead of derived from the brand until CADS service is implemented
        catalog: 'atc',
        brand,
        consumerInsights: personalization,
        consumerAdTargets,
        consumerAdTargetsWithoutCAMPBias,
        consumerMarketingSegments,
        consumerMetadata,
        application: {
            name: 'web',
            environment: publicRuntimeConfig.environment === 'prod' ? 'production' : 'qa',
            version: '1.0.0',
        },

        get user() {
            return {
                adBlockerState: 'off',
                get isLoggedIn() {
                    return typeof window !== 'undefined' && (!!isLoggedInRef.current || '0');
                },
                ivtMoatFlag: '0',
                zipCode: userZipCode,
                dma: dataIsland?.page?.location?.dma,
            };
        },
        page: {
            pageName,
            pg,
            brandPg,
            siteSection,
            pageType,
            detailPageName,
            experimentId: activeExperiments,
            pixallId: cookies.pxa_id,
            pixallABC: cookies.abc || cookies.pixall_abc,
            pixallPageInstanceId: dataIsland?.page?.BIRF?.pg_inst,
            testName: activeExperiments,
            // SRP will have 0 as default
            // VDP did not have this value, should this be 0, undefined or anything else?
            experimentBoosts: totalBoostCount,
        },
        search: {
            pagination: currentPage,
            // undefined means empty, null is a valid value, leave keyword: null here to be safe
            keyword: null,
            sortType: sort,
            filters: {
                ...filters,
                ...overrideFilterValues,
            },
            zipCode: zip,
        },

        device,

        inventory,

        query,

        vehicle,

        matchAlphaZone,
    };

}
